export default Object.freeze({
  doMouseDown         : 'doMouseDown',
  didMouseDown        : 'didMouseDown',
  doMouseDrag         : 'doMouseDrag',
  didMouseDrag        : 'didMouseDrag',
  doMouseUp           : 'doMouseUp',
  didMouseUp          : 'didMouseUp',
  doKeyDown           : 'doKeyDown',
  didKeyDown          : 'didKeyDown',
  doAddImage          : 'doAddImage',
  didAddImage         : 'didAddImage',
  doClearBoard        : 'doClearBoard',
  didClearBoard       : 'didClearBoard',
  doRemoveItem        : 'doRemoveItem',
  didRemoveItem       : 'didRemoveItem',
  doBringItemForward  : 'doBringItemForward',
  didBringItemForward : 'didBringItemForward',
  doSendItemBackward  : 'doSendItemBackward',
  didSendItemBackward : 'didSendItemBackward',
  doSelectItem        : 'doSelectItem',
  didSelectItem       : 'didSelectItem',
  doOperateItem       : 'doOperateItem',
  didOperateItem      : 'didOperateItem',
  doCreateText        : 'doCreateText',
  didCreateText       : 'didCreateText',
  doUpdateText        : 'doUpdateText',
  didUpdateText       : 'didUpdateText',
  doUnselectText      : 'doUnselectText',
  didUnselectText     : 'didUnselectText',
  doSendBoardState    : 'doSendBoardState',
  didSendBoardState   : 'didSendBoardState',
});
