import { Point } from 'paper';

import { bounds } from '../../constants';

const minSize = 20; // Min width or height of the resized item (in px)

/**
 * Perform a resizing operation (triggered by the local user).
 * The resizing has to be done on both the item itself
 * and the item that displays the manipulation handlers.
 *
 * @param {Object} selectedItem Object with selected item in drawings and map canvases.
 * @param {Object} originBound Bound used as origin for dragging action.
 * @param {Object} Point Current point generated by dragging the user cursor.
 */
export default function resize({ drawings, map, handlers }, originBound, point) {
  if (!drawings || !map) {
    return undefined;
  }

  /**
   * First, revert the current item rotation to the key points
   * so the calculations based on their x,y coordinates are correct.
   */
  const rotatedTopLeft = handlers.children.topLeft.position.rotate(
    -1 * drawings.rotation, drawings.position,
  );
  const rotatedTopRight = handlers.children.topRight.position.rotate(
    -1 * drawings.rotation, drawings.position,
  );
  const rotatedBottomRight = handlers.children.bottomRight.position.rotate(
    -1 * drawings.rotation, drawings.position,
  );

  const dragPoint = new Point(point);
  const rotatedDragPoint = dragPoint.rotate(-1 * drawings.rotation, drawings.position);

  // Calculate current dimensions of the item
  const width = rotatedBottomRight.x - rotatedTopLeft.x;
  const height = rotatedBottomRight.y - rotatedTopLeft.y;

  // Calculate horizontal and vertical scaling factors based on drag point
  let x;
  let y;
  let originPoint;

  switch (originBound) {
    case bounds.bottomRight:
      originPoint = handlers.children.topLeft.position;
      x = (rotatedDragPoint.x - rotatedTopLeft.x) / width;
      y = (rotatedDragPoint.y - rotatedTopLeft.y) / height;
      break;

    case bounds.bottomLeft:
      originPoint = handlers.children.topRight.position;
      x = (rotatedTopRight.x - rotatedDragPoint.x) / width;
      y = (rotatedDragPoint.y - rotatedTopRight.y) / height;
      break;

    default:
      return undefined;
  }

  // Get the final scaling factor - the bigger one
  let scalingFactor = Math.max(x, y);

  // Do not allow to make the item smaller than a certain size
  if (width * scalingFactor <= minSize || height * scalingFactor <= minSize) {
    scalingFactor = 1;
  }

  // Scale the drawings and map items
  drawings.scale(scalingFactor, originPoint);
  map.scale(scalingFactor, originPoint);

  return { scalingFactor, originPoint };
}
