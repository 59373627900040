export default {
  minLayoutWidth    : '380px',
  minContentHeight  : '640px',
  miscSectionWidth  : '1200px',
  header            : '80px',
  footer            : '80px',
  buttonWidth       : '100px',
  buttonWidthMd     : '180px',
  buttonWidthLg     : '300px',
  cardWidth         : '500px',
  notificationWidth : '240px',
  itemMenuWidth     : '160px',
  formHeight        : '34px',
  formHeightMd      : '50px',
  formHeightLg      : '80px',
  toolButtonSm      : '30px',
  toolButtonMd      : '38px',
  toolButtonLg      : '44px',
};
